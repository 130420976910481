<template>
  <div v-if="!!yearlyRosetteInfo.length" class="history-container">
    <VueText class="history-title">
      Geçmiş Aylar Özetim
    </VueText>
    <div class="history-rosettes">
      <div v-for="(item, index) in yearlyRosetteInfo" :key="index" class="history-rosette-item">
        <div class="rosette-wrapper">
          <img :src="getBadgeImage(item.status)" alt="rosette" class="rosette-image" />
          <VueText :style="monthTextStyle(item)" class="month">
            {{ item.month.toUpperCase() }}
          </VueText>
          <VueText class="point" v-if="item.point">
            {{ `${item.point}&nbsp;Puan` }}
          </VueText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { Gamification } from '@/services/Api/index';

export default {
  components: {
    VueText,
  },
  data() {
    return {
      yearlyRosetteInfo: [],
    };
  },
  props: {
    triggerRefresh: {
      type: Boolean,
    },
  },
  watch: {
    triggerRefresh: {
      handler() {
        this.getRosetteHistory();
      },
    },
  },
  computed: {
    monthTextStyle() {
      return item => {
        return {
          'text-shadow': item.status !== 4 ? this.getMonthNameStyle(item.status) : 'none',
          color: item.status !== 4 ? 'white' : '#CDCFCF',
        };
      };
    },
  },
  mounted() {
    this.getRosetteHistory();
  },
  methods: {
    getRosetteHistory() {
      Gamification.getYearlyRosetteInfo().then(res => {
        this.yearlyRosetteInfo = res.data.Data.rosetteSuccesInfo;
      });
    },
    getMonthNameStyle(status) {
      const styles = {
        1: '1px 1px #EAC013',
        2: '1px 1px #EAC013',
        3: '1px 1px #FD9292',
        default: '1px 1px #CDCFCF',
      };
      return styles[status] || styles.default;
    },
    getBadgeImage(status) {
      const badgeImages = {
        1: require('/src/assets/rosettes/success-rosette.svg'),
        2: require('/src/assets/rosettes/success-rosette.svg'),
        3: require('/src/assets/rosettes/fail-rosette.svg'),
        default: require('/src/assets/rosettes/passive-rosette.svg'),
      };
      return badgeImages[status] || badgeImages.default;
    },
  },
};
</script>

<style scoped lang="scss">
.history-container {
  margin: 5% 0 0 2%;
}

.history-title {
  font-size: 24px;
  font-weight: 400;
  margin: 60px 0 0 20px;
}

.history-rosettes {
  margin: 2%;
  width: 984px;
  height: 368px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  background-color: #f9fdff;
  border: 1px solid #eef4f6;
}

.history-rosette-item {
  text-align: center;
  margin-top: 40px;
  margin-left: 50px;
}

.rosette-image {
  width: 105px;
  height: 124px;
}

.rosette-wrapper {
  position: relative;
  display: inline-block;
}

.point {
  top: 55px;
  left: 50%;
  padding: 5px;
  font-size: 18px;
  font-weight: 700;
  color: #b77c0a;
  font-family: Play;
  position: absolute;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px #c8a83575;
}

.month {
  top: 25%;
  left: 50%;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.94);
}
</style>
